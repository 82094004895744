import axios from '@axios'
import endpoints from '@/http/endpoints/index'

export default {
  // CommonHub
  async fetchCommonHub(id) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.commonEntities.Controller.getCommonHubById(id))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async fetchCommonHubObjectIdByCommonHubId(commonHubId) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.commonEntities.Controller.getCommonHubObjectIdByCommonHubId(commonHubId))
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  // commonHubType: data: {commonHubType: 'user|customer|commonEntities|franchise|warehouse|carrier|ecommerce_store|manufacturer'}
  async createCommonHub(data) {
    const promise = new Promise((resolve, reject) => {
      axios
        .post(endpoints.commonEntities.Controller.createCommonHub(), data)
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  // Phones
  async fetchPhoneById(id) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.commonEntities.Controller.findPhoneById(id))
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async fetchPhonesByCommonHub(commonHub) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.commonEntities.Controller.findPhonesByCommonHub(commonHub))
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async fetchPhonesByType(commonHub, phoneType) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.commonEntities.Controller.findPhonesByType(commonHub, phoneType))
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async createPhone(data) {
    const promise = new Promise((resolve, reject) => {
      axios
        .post(endpoints.commonEntities.Controller.createPhone(), data)
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async updatePhone(data) {
    const promise = new Promise((resolve, reject) => {
      axios
        .put(endpoints.commonEntities.Controller.updatePhone(), data)
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  async deletePhoneById(id) {
    const promise = new Promise((resolve, reject) => {
      axios
        .delete(endpoints.commonEntities.Controller.deletePhoneById(id))
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  // Emails
  async fetchEmailById(id) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.commonEntities.Controller.findEmailById(id))
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async fetchEmailsByCommonHub(commonHub) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.commonEntities.Controller.findEmailsByCommonHub(commonHub))
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async createEmail(data) {
    const promise = new Promise((resolve, reject) => {
      axios
        .post(endpoints.commonEntities.Controller.createEmail(), data)
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async updateEmail(data) {
    const promise = new Promise((resolve, reject) => {
      axios
        .put(endpoints.commonEntities.Controller.updateEmail(), data)
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async deleteEmailById(id) {
    const promise = new Promise((resolve, reject) => {
      axios
        .delete(endpoints.commonEntities.Controller.deleteEmailById(id))
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  // Addresses
  async fetchAddressById(id) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.commonEntities.Controller.findAddressById(id))
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async fetchAddressesByCommonHub(commonHub) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.commonEntities.Controller.findAddressesByCommonHub(commonHub))
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async fetchAddressesByType(commonHub) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.commonEntities.Controller.findAddressesByType(commonHub))
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async createAddress(data) {
    const promise = new Promise((resolve, reject) => {
      axios
        .post(endpoints.commonEntities.Controller.createAddress(), data)
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async updateAddress(data) {
    const promise = new Promise((resolve, reject) => {
      axios
        .put(endpoints.commonEntities.Controller.updateAddress(), data)
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async deleteAddressById(id) {
    const promise = new Promise((resolve, reject) => {
      axios
        .delete(endpoints.commonEntities.Controller.deleteAddressById(id))
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  // Stores
  async fetchECommerceStoreById(id) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.commonEntities.Controller.findECommerceStoreById(id))
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async fetchStoresByParentCompany(id) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.commonEntities.Controller.getStoresByParentCompany(id))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async fetchStoresByParentCompanyAccountNo(accountNo) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.commonEntities.Controller.findStoresByParentCompanyAccountNo(accountNo))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },
  async fetcStoresByCompanyAccountNoAndChannel(accountNo, platform) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.commonEntities.Controller.getStoresByCompanyAccountNoAndChannel(accountNo, platform))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async fetchStoresForCurrentCompany() {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.commonEntities.Controller.findStoreForCurrentCompany())
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async fetchStoreByCommonHub(commonHub) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.commonEntities.Controller.findStoreByCommonHub(commonHub))
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async createStore(data) {
    const promise = new Promise((resolve, reject) => {
      axios
        .post(endpoints.commonEntities.Controller.createStore(), data)
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async updateStore(data) {
    const promise = new Promise((resolve, reject) => {
      axios
        .put(endpoints.commonEntities.Controller.updateStore(), data)
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async deleteStoreById(id) {
    const promise = new Promise((resolve, reject) => {
      axios
        .delete(endpoints.commonEntities.Controller.deleteStoreById(id))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  // Credentials

  async fetchCredentialsById(id) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.commonEntities.Controller.findCredentialsById(id))
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async fetchCredentialsByCommonHub(commonHub) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.commonEntities.Controller.findCredentialsByCommonHub(commonHub))
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async fetchCredentialsByAccountNo(filter, pageable = {}) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.commonEntities.Controller.findCredentialsByAccountNo(filter, pageable))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async createCredentials(data) {
    const promise = new Promise((resolve, reject) => {
      axios

        .post(endpoints.commonEntities.Controller.createCredentials(), data)
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async updateCredentials(data) {
    const promise = new Promise((resolve, reject) => {
      axios
        .put(endpoints.commonEntities.Controller.updateCredentials(), data)
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async deleteCredentialsById(id) {
    const promise = new Promise((resolve, reject) => {
      axios
        .delete(endpoints.commonEntities.Controller.deleteCredentialsById(id))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async fetchAddressesByTenantAndCompany(id) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.commonEntities.Controller.findAddressesByTenantAndCompany(id))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },
  async getAmazonAddresses() {
    const result = await axios.get(endpoints.commonEntities.Controller.getAmazonAddress())
    return result
  },
  async getAmazonAddressById(id) {
    const result = await axios.get(endpoints.commonEntities.Controller.getAmazonAddressById(id))
    return result.data.body
  },
  async findPhonesByTenantAndCompany() {
    const result = await axios.get(endpoints.commonEntities.Controller.findPhonesByTenantAndCompany())
    return result.data.body
  },
  async setAskAuth(storeId) {
    const result = await axios.get(endpoints.commonEntities.Controller.setAskAuth(storeId))
    return result
  },
  async unsetAskAuth(storeId) {
    const result = await axios.get(endpoints.commonEntities.Controller.unsetAskAuth(storeId))
    return result
  },
  async toggleFBMConnection(storeId, toggleValue) {
    const result = await axios.post(endpoints.commonEntities.Controller.toggleFBMConnection(storeId, toggleValue))
    return result
  },
  async checkRdtStatus(storeId) {
    const result = await axios.get(endpoints.commonEntities.Controller.checkRdtStatus(storeId))
    return result.data
  },
}
